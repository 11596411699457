
    .video-master {
        position: relative;
        .stop-icon-box {
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
            width: 26px;
            height: 26px;
            z-index: 999;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
                color: #8C8C8C;
                font-size: 20px;
            }
        }
    }
    .img-box {
        position: relative;
        .play-box {
            z-index: 999;
            cursor: pointer;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            span.video-icon {
                width: 50px;
                height: 50px;
                background: url("../../../assets/images/main-circles.png") 0 -55px no-repeat;
                &:hover {
                    background: url("../../../assets/images/main-circles.png") -55px -55px no-repeat;
                }
            }
        }

    }
    .store-wrapper {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        background: #fff;
    }
    .store-container {
        /*min-width: 1200px;*/
        height: 100vh;
        /deep/ .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__view {
                min-width: 1200px;
            }
        }
        .web-top-wrapper {
            .web-top {
                width: 1200px;
                height: 106px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .top-left {
                    display: flex;
                    .web-logo {
                        width: 134px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 60px;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    /deep/ .el-select {
                        /*margin-left: 60px;*/
                        .el-input__inner {
                            border-radius: 0;
                        }
                    }
                }
                /deep/ .top-search {
                    display: flex;
                    align-items: center;
                    .el-input {
                        .el-input__inner {
                            border-radius: 0;
                            border-width: 2px 0 2px 2px;
                            border-color: #DF0200;
                            border-style: solid;
                        }
                    }
                    .btn {
                        width: 60px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        color: #fff;
                        cursor: pointer;
                        &.search-all {
                            background: #B61D1D;
                        }
                        &.search-current {
                            background: #474E5C;
                        }
                    }
                }
            }
        }
        .web-tab-wrapper {
            background: #F2F2F2;
            .web-tab {
                width: 1200px;
                margin: 0 auto;
                height: 40px;
                line-height: 40px;
                color: #666;
                .tab-item {
                    color: #fff;
                    background: #B61D1D;
                    width: 108px;
                    text-align: center;
                }
            }
        }
        .good-info-wrapper {
            margin-top: 20px;
            .good-info {
                width: 1200px;
                margin: 0 auto;
                display: flex;
                .good-cover {
                    position: relative;
                    .swiper-container {
                        width: 350px;
                        height: 450px;
                        .swiper-slide {
                            width: 350px!important;
                            /*height: 560px;*/
                            height: 350px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #fafafa;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                                /*width: 100%;*/
                                /*height: 100%;*/
                            }
                        }
                    }
                    /deep/ .swiper-pagination {
                        width: 350px;
                        display: flex;
                        margin: 20px -5px;
                        bottom: 0;
                        .swiper-pagination-bullet {
                            /*width: 96px;*/
                            width: 60px;
                            height: 60px;
                            background: #fafafa;
                            border: 2px solid transparent;
                            box-sizing: border-box;
                            margin: 0 5px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            opacity: 1;
                            border-radius: 0;
                            img {
                                display: block;
                                max-width: 100%;
                                max-height: 100%;
                            }
                            &.swiper-pagination-bullet-active {
                                border-color: #B61D1D;
                            }
                        }
                    }
                }
                .right-content {
                    margin-left: 40px;
                    width: 1%;
                    flex: 1;
                    .good-title {
                        font-size: 16px;
                        font-weight: bold;
                    }
                    .good-price {
                        margin-top: 20px;
                        background: #F7F7F7;
                        height: 60px;
                        display: flex;
                        align-items: center;
                        padding-left: 16px;
                        .price {
                            color: #999;
                            .text {

                            }
                            .num {
                                margin-left: 10px;
                                font-size: 16px;
                                text-decoration: line-through;
                            }
                        }
                        .sales-price {
                            margin-left: 30px;
                            .text {
                                color: #999;
                            }
                            .num {
                                margin-left: 10px;
                                color: #FF0000;
                                font-size: 16px;
                            }
                        }
                    }
                    .good-address {
                        margin-top: 20px;
                        color: #999;
                        .text {
                            margin-right: 20px;
                        }
                        .el-select {
                            margin-right: 20px;
                        }
                    }
                    .good-color {
                        margin-top: 20px;
                        display: flex;
                        .text {
                            color: #999;
                        }
                        .color-content {
                            flex: 1;
                            width: 1%;
                            margin-left: 20px;
                            display: flex;
                            .color-img {
                                width: 60px;
                                height: 60px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 1px solid #B61D1D;
                                box-sizing: border-box;
                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }
                            .color-text {
                                margin-left: -1px;
                                border: 1px solid #B61D1D;
                                box-sizing: border-box;
                                background: #F7F7F7;
                                width: 60px;
                                height: 60px;
                                text-align: center;
                                line-height: 60px;
                            }
                        }
                    }
                    .good-num {
                        margin-top: 20px;
                    }
                    .good-btn {
                        margin-top: 20px;
                    }
                }
            }
        }
        .content-wrapper {
            .web-tab-wrapper {
                background: #F2F2F2;
                .web-tab {
                    width: 1200px;
                    margin: 0 auto;
                    .tab-item {
                        width: 120px;
                        line-height: 40px;
                        background: #B61D1D;
                        text-align: center;
                        color: #fff;
                    }
                }
            }
            .detail-content {
                text-align: center;
                width: 1200px;
                margin: 20px auto;
                img {
                    max-width: 100%;
                    display: inline-block;
                    margin-top: 20px;
                }
            }
        }
    }
    /deep/ p {
        margin: 0;
    }
    .btn-red-dark {
        width: 140px;
        height: 46px;
        border-radius: 0;
        background: #B61D1D;
        border-color: #B61D1D;
        color: #fff;
    }
